import styled from 'styled-components';

export default styled.div`
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  display: table;
  padding: 40px 20px;
  /* overflow: hidden; */
  box-sizing: border-box;
`;
