export { default as HealthAssessmentRoutedConnected } from './HealthAssessment';
export { default as HealthAssessmentConnected } from './HealthAssessmentStatic';
export { HealthAssessmentPage } from './HealthAssessmentPage';
export * from './HealthAge';
export * from './Risks';
export { MyNumbers } from './ActionsAndNumbers/MyNumbers';
export { default as CheckableConclusions } from './Conclusions/CheckableConclusions';
export { default as WellbeingScores } from './Wellbeing/WellbeingScores';
export { CheckableConclusionWithHeader } from './Conclusions/CheckableConclusionWithHeader';
export { AdditionalInfo } from './AdditionalInfo/AdditionalInfo';
export { ComparisonReportPage } from './ComparisonReportPage';
