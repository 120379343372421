import React from 'react';

const CrossIcon: React.FC = () => {
  return (
    <div>
      <svg width="12px" height="12px" viewBox="0 0 12 12">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-341.000000, -347.000000)" fill="#E20404">
            <g transform="translate(0.000000, 284.000000)">
              <g transform="translate(16.000000, 38.000000)">
                <path
                  d="M336.664795,25.335205 C337.111735,25.782145 337.111735,26.5067779 336.664795,26.9537178 L332.618,31 L336.664795,35.0462822 C337.111735,35.4932221 337.111735,36.217855 336.664795,36.664795 C336.217855,37.111735 335.493222,37.111735 335.046282,36.664795 L331,32.618 L326.953718,36.664795 C326.506778,37.111735 325.782145,37.111735 325.335205,36.664795 C324.888265,36.217855 324.888265,35.4932221 325.335205,35.0462822 L329.382,31 L325.335205,26.9537178 C324.888265,26.5067779 324.888265,25.782145 325.335205,25.335205 C325.782145,24.888265 326.506778,24.888265 326.953718,25.335205 L331,29.382 L335.046282,25.335205 C335.493222,24.888265 336.217855,24.888265 336.664795,25.335205 Z"
                  id="red-cross"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default CrossIcon;
