export interface ColorsTheme {
  white: string;
  black: string;
  grey100: string;
  grey200: string;
  grey250: string;
  grey300: string;
  grey400: string;
  brand50: string;
  brand100: string;
  brand200: string;
  brand300: string;
  red100: string;
  red200: string;
  red300: string;
  redgreen: string;
  lightBlue100: string;
  linkBlue: string;
  orange100: string;
  orange200: string;
  lightYellow100: string;
  green100: string;
  green200: string;
  greenyellow: string;
  danger: string;
  moderate: string;
  normal: string;
  chartTurquoise: string;
  chartPink: string;
  chartGreen: string;
  chartRed: string;
  dropdownBorder: string;
  dropdownArrowBackground: string;
  warningFill: string;
  warningExclamation: string;
  dividerBackground: string;
  dividerBoxShadow: string;
  toggleAssessmentBorder: string;
  inputFocus: string;
  iconFocus: string;
}

const colors: ColorsTheme = {
  white: '#ffffff',
  black: '#000000',
  grey100: '#fcfcfc',
  grey200: '#dedede',
  grey250: '#9a9a9a',
  grey300: '#575757',
  grey400: '#343434',
  brand50: '#F1F1FD',
  brand100: '#1018D5',
  brand200: '#090E7F',
  brand300: '#04073F',
  red100: '#dc0000',
  red200: '#b30000',
  red300: '#e50707',
  redgreen: '#ff6400',
  lightBlue100: '#00C4FA',
  linkBlue: '#406FD6',
  orange100: '#F0A700',
  orange200: '#ffcc00',
  lightYellow100: '#FFED7B',
  green100: '#19C454',
  green200: '#24a31b',
  greenyellow: '#b6ef17',
  danger: '#e42817',
  moderate: '#ff7a00',
  normal: '#008000',
  chartTurquoise: '#1eddc5',
  chartPink: '#e42dad',
  chartGreen: '#0DBEA4',
  chartRed: '#E20404',
  dropdownBorder: '#c8c8c8',
  dropdownArrowBackground: '#EDEDED',
  warningFill: '#FFDE33',
  warningExclamation: '#495A79',
  dividerBackground: '#f5f5f5',
  dividerBoxShadow: '#e0e0e0',
  toggleAssessmentBorder: '#e2e2e2',
  inputFocus: '#00C4FA',
  iconFocus: '#00C4FA',
};

export default colors;
